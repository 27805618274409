@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;1,300;1,400&family=Open+Sans:wght@300;400&display=swap');



@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');


:root {
    --main-bg-color: #f7f7f7;
    --main-bg-color-dark: #f7fbec;

    --deep-blue: #4F6367;
    --deep-blue-light: #5c7377;

    --light-blue: #4F709C;


    --default-font: 'Montserrat', sans-serif;
    --header-font: 'Open Sans', sans-serif;
}

* {
    letter-spacing: .5px;
    font-family: 'Lato', sans-serif;
}

.main_bg {
    background-color: var(--main-bg-color);
}

.blue_font {
    color: var(--deep-blue);
}

.App {
    min-height: 100vh;
}

header {
    background-color: var(--deep-blue-light);
    /* background-color: #6e7580; */
    font-family: var(--header-font);
}




.agent_header {
    background-color: var(--deep-blue);

}

.stay-left {
    left: -200px;
}

.stay-open {
    left: 0;
}

footer {
    flex-grow: 0;
    color: white;
    background-color: var(--deep-blue) !important;
}

.logoImg {
    mix-blend-mode: hard-light;
}



.userInfoCard {
    position: absolute;
    bottom: -170px;
    z-index: 1;
    background-color: var(--main-bg-color);
    border: 2px solid #6e7580;
    /* padding: 10px 15px; */
}

.main_container {
    flex-grow: 3;
}

.secondary_nav {
    background-color: var(--light-yellow);
}

img {
    mix-blend-mode: darken;
}

.button_1,
.button_2 {
    padding: .3rem 1rem;
    border-radius: .25rem;
    margin: 0.5rem 0 0 0;
    border: none
}

.button_1 {
    background-color: var(--deep-blue-light) !important;
    border: 1px solid var(--deep-blue);
    transition: .2s ease-in-out;
    color: white;
}

.button_2 {
    background-color: var(--main-bg-color) !important;
    color: var(--deep-blue);
    border: 1px solid var(--deep-blue);
    transition: .2s ease-in-out;
}

.button_1:hover {
    background-color: var(--deep-blue-light) !important;
}

.button_2:hover {
    background-color: var(--main-bg-color-dark) !important;
}

/* .a_line{
    padding: 0 .5rem;
    width: 3rem;
    position: relative;
    padding: 1rem .5rem;

}
.a_line::before{
    content: "";
    position: absolute;
    height: 10px;
    background-color: var(--deep-blue);
    width: inherit;
    top:-10px;
    right: 10px;
    transition: .5s ease-in-out;
    
}
.a_line:hover.a_line::before{
    top:-1px;
    right: 3px;
} */
.basis {
    flex: 3 1 auto;
}

.grow-2 {
    flex-grow: 2;
    min-width: 60%;

}

input:focus,
textarea:focus {
    border: none;
    box-shadow: 0px 0px 3px var(--deep-blue) !important;
}



.button_1 span {
    height: 40px;
}

.loading {
    width: 20px;
    height: 20px;
    background-color: transparent;
    position: relative;
    animation: rotate .4s linear infinite;
    border: 3px solid white;
    border-radius: 50%;
    border-bottom-color: transparent;

}

.loading-ticket {
    width: 30px;
    height: 30px;
    margin-top: 5%;
    background-color: var(--main-bg-color) !important;
    position: relative;
    animation: rotate 1s linear infinite;
    animation-delay: .2s;
    border-radius: 50%;
    border: 5px solid var(--deep-blue);
    border-bottom-color: rgb(237, 229, 229);
}





@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.border-8 {
    border-width: 8px;
}

.border-4 {
    border-width: 4px;
}

.border-2 {
    border-width: 2px;
}

.rounded-5px {
    border-radius: 5px;
}

.tr-bg {
    background-color: #5e6a6c37;
    cursor: pointer;
}

.th-bg {
    background-color: #363c3d5f;
    cursor: pointer;
}

.tr-bg:hover {
    background-color: #89919237;
}

table {
    width: 100%;
    overflow-x: scroll;
}